<script setup>
import Button from '@/Components/Button/Button.vue';
import Card from '@/Components/DataCard.vue';
import { objectMap } from '@/utilities';
import { useForm, usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
const route = inject('route');
const user_id = computed(() => usePage().props.user_id);

// TODO: this file can be deleted if update password successfully works unside UpdateForm

const form = useForm({
    current_password: '',
    password: '',
    password_confirmation: '',
});

const sendForm = () => {
    console.log(user_id.value);
    form.put(route('location.profile.update-password'), {
        preserveState: true,
        preserveScroll: true,
        onError: (errors) => (form.errors = objectMap(errors, (error) => [error])),
    });
};
</script>

<template>
    <Card :title="$t('Update password')">
        <FormKit type="form" id="password-update-form" @submit="sendForm" :actions="false">
            <div class="grid gap-5">
                <FormKit
                    type="password"
                    :placeholder="$t('Current {attribute}', { attribute: 'password' })"
                    name="current_password"
                    :label="$t('Current {attribute}', { attribute: 'password' })"
                    v-model="form.current_password"
                    :errors="form.errors.current_password"
                    :value="form.current_password"
                />
                <FormKit
                    type="password"
                    :placeholder="$t('New {attribute}', { attribute: 'password' })"
                    name="password"
                    :label="$t('New {attribute}', { attribute: 'password' })"
                    v-model="form.password"
                    :errors="form.errors.password"
                    :value="form.password"
                />
                <FormKit
                    type="password"
                    :placeholder="$t('Repeat {attribute}', { attribute: 'password' })"
                    name="password_confirmation"
                    :label="$t('Repeat {attribute}', { attribute: 'password' })"
                    v-model="form.password_confirmation"
                    :errors="form.errors.password_confirmation"
                    :value="form.password_confirmation"
                />

                <Button
                    :processing="form.processing"
                    :recentlySuccessful="form.recentlySuccessful"
                    text="Wijzigingen opslaan"
                    icon="/icon/done-white.svg"
                />
            </div>
        </FormKit>
    </Card>
</template>
;
